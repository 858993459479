import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">


<path d="M6017 10733 c-14 -13 -6 -22 23 -28 51 -11 55 -29 60 -285 l5 -235
28 -51 c18 -32 42 -59 65 -72 114 -62 326 -49 406 26 62 59 70 93 76 350 5
231 5 234 29 253 13 10 34 19 47 19 15 0 24 6 24 15 0 13 -23 15 -146 15 -153
0 -177 -9 -99 -36 22 -7 45 -21 52 -30 18 -25 25 -123 21 -305 -3 -161 -4
-167 -30 -207 -39 -58 -78 -76 -163 -77 -82 0 -128 21 -160 75 -18 32 -20 55
-23 260 -2 139 1 236 7 252 8 22 19 29 51 34 24 4 40 12 40 20 0 11 -30 14
-153 14 -85 0 -157 -3 -160 -7z"/>
<path d="M6940 10720 c0 -5 14 -17 31 -25 17 -9 37 -31 45 -51 13 -31 32 -185
67 -559 12 -116 13 -120 38 -129 15 -5 33 -6 42 -3 8 3 52 61 97 129 46 68 86
125 89 128 3 3 28 38 55 78 27 39 53 72 57 72 11 0 16 -53 30 -288 7 -121 16
-206 23 -211 6 -5 23 -12 37 -16 29 -7 18 -21 310 405 54 80 106 151 116 158
9 6 31 12 49 12 31 0 53 15 41 27 -3 3 -56 18 -118 35 -77 20 -115 26 -122 19
-6 -6 -9 -12 -6 -13 2 -2 18 -10 34 -19 52 -27 41 -56 -96 -264 -67 -102 -126
-182 -129 -178 -11 11 -29 418 -21 457 6 27 12 32 46 36 74 10 47 26 -103 63
-82 20 -151 37 -155 37 -16 0 -5 -20 28 -53 49 -49 47 -80 -13 -164 -26 -38
-78 -114 -116 -170 -68 -102 -86 -119 -86 -85 0 9 -5 62 -10 117 -37 376 -38
365 30 365 26 0 40 5 40 13 0 8 -53 26 -152 50 -164 40 -178 42 -178 27z"/>
<path d="M5633 10673 c-99 -24 -143 -39 -143 -49 0 -10 12 -14 40 -14 30 0 43
-5 55 -23 20 -30 68 -228 58 -237 -17 -14 -324 -83 -332 -74 -10 10 -41 143
-48 207 -6 41 7 64 44 78 41 15 27 32 -19 25 -107 -17 -258 -60 -258 -72 0 -9
12 -14 33 -14 54 0 62 -14 96 -155 12 -50 38 -154 57 -232 43 -172 42 -212 -3
-233 -29 -13 -46 -40 -26 -40 4 0 76 17 160 37 97 24 153 42 153 50 0 9 -15
13 -43 13 -29 0 -49 6 -60 18 -20 22 -77 251 -65 258 17 11 322 83 329 78 11
-7 61 -229 57 -256 -2 -15 -17 -30 -40 -43 -45 -24 -52 -50 -10 -40 250 56
354 95 254 95 -26 0 -45 6 -57 20 -10 11 -35 92 -61 197 -23 98 -50 206 -58
240 -9 34 -16 80 -16 103 0 35 4 43 30 58 30 17 36 27 23 35 -5 3 -72 -11
-150 -30z"/>
<path d="M4748 10378 c-3 -21 -9 -23 -65 -25 -190 -6 -312 -173 -226 -309 49
-77 82 -88 281 -94 l174 -5 25 -29 c52 -61 11 -155 -85 -197 -84 -37 -182 -18
-241 45 -14 14 -30 26 -38 26 -15 0 -17 -12 -4 -26 4 -5 28 -46 51 -91 45 -85
64 -104 58 -55 l-3 27 81 6 c97 7 160 34 219 93 58 59 78 107 73 173 -5 62
-26 95 -87 138 -46 32 -28 31 -374 35 -43 0 -77 40 -77 91 0 33 6 47 33 74 47
47 103 60 170 41 29 -9 65 -27 81 -41 31 -26 46 -32 46 -15 0 15 -74 160 -81
160 -4 0 -9 -10 -11 -22z"/>
<path d="M8177 10025 c-142 -118 -267 -220 -278 -226 -11 -6 -39 -9 -61 -7
-23 2 -43 -2 -45 -8 -4 -13 203 -121 213 -111 5 4 -5 18 -20 31 -39 31 -31 59
34 120 l51 47 77 -42 c42 -24 94 -52 114 -63 21 -12 39 -25 41 -31 2 -5 -1
-41 -8 -78 -11 -67 -27 -92 -53 -82 -80 30 -84 2 -5 -40 31 -16 95 -51 142
-77 47 -27 89 -48 94 -48 15 0 6 24 -23 57 -20 22 -30 44 -30 66 0 33 30 332
49 487 24 199 24 186 -3 204 -14 9 -27 16 -29 16 -1 0 -119 -97 -260 -215z
m170 13 c-8 -81 -30 -232 -34 -238 -6 -7 -193 94 -192 105 0 12 208 185 222
185 5 0 7 -22 4 -52z"/>
<path d="M3828 9884 c-113 -98 -130 -128 -50 -89 28 14 34 14 60 1 15 -8 77
-72 137 -143 61 -70 122 -141 137 -158 28 -30 35 -45 21 -45 -5 0 -146 36
-315 79 l-308 79 -33 -27 c-96 -76 -157 -135 -148 -144 5 -5 17 -5 29 2 59 31
84 13 268 -204 145 -170 174 -210 174 -238 0 -11 -22 -42 -49 -68 -45 -45 -52
-49 -97 -49 -28 0 -183 35 -359 80 -219 56 -313 76 -322 68 -33 -27 -24 -47
152 -334 99 -161 177 -297 177 -312 1 -15 -2 -42 -6 -59 -13 -56 14 -38 83 56
36 48 66 92 69 99 6 19 -23 14 -43 -8 -10 -11 -26 -20 -36 -20 -19 0 -71 55
-94 101 l-16 30 78 105 c42 57 82 104 88 104 26 0 115 -32 136 -48 21 -18 22
-20 5 -52 -11 -22 -13 -37 -6 -44 5 -5 11 -8 13 -5 2 2 49 65 104 139 77 102
123 153 192 210 83 69 117 113 74 95 -79 -32 -83 -29 -278 200 -27 33 -70 82
-94 110 -39 43 -42 50 -25 52 10 2 167 -35 348 -81 l330 -83 42 39 43 40 -117
136 c-64 76 -119 139 -122 142 -13 12 -119 138 -143 171 -33 43 -34 85 -5 116
13 14 18 27 12 32 -5 6 -47 -24 -106 -75z m-608 -1033 c101 -29 135 -42 134
-54 -2 -17 -112 -167 -123 -167 -7 0 -161 243 -161 254 0 9 6 8 150 -33z"/>
<path d="M6255 9670 c-132 -8 -285 -25 -355 -39 -25 -5 -74 -15 -110 -22 -167
-31 -444 -115 -580 -176 -30 -14 -73 -32 -95 -41 -50 -19 -305 -149 -325 -165
-8 -7 -31 -21 -50 -31 -62 -34 -252 -168 -355 -250 -151 -121 -368 -341 -524
-531 -27 -34 -191 -265 -191 -270 0 -2 -15 -29 -34 -58 -19 -29 -67 -118 -108
-198 -65 -127 -90 -183 -168 -379 -112 -285 -200 -775 -200 -1120 0 -94 31
-458 46 -535 62 -328 134 -564 248 -810 50 -110 146 -298 155 -305 3 -3 15
-21 25 -40 36 -66 164 -252 225 -330 20 -25 39 -49 43 -55 57 -80 348 -370
478 -475 231 -187 522 -361 790 -475 84 -36 335 -125 380 -136 19 -4 53 -13
75 -19 107 -29 356 -74 497 -89 146 -16 542 -14 683 4 321 41 545 96 850 209
82 31 362 167 455 222 126 75 190 119 330 223 275 207 554 503 736 781 27 41
52 77 55 80 3 3 16 25 29 50 14 25 30 52 36 60 23 29 161 315 207 430 170 426
248 893 226 1355 -10 205 -14 245 -40 405 -20 120 -89 411 -100 420 -3 3 -9
19 -13 35 -29 125 -167 439 -273 623 -170 296 -333 503 -588 747 -278 267
-602 485 -925 625 -120 51 -132 57 -190 79 -119 45 -138 50 -344 106 -285 76
-682 114 -1001 95z m565 -276 c246 -33 504 -95 675 -162 28 -11 75 -30 105
-42 107 -42 286 -134 413 -212 114 -70 150 -94 236 -156 177 -127 351 -296
571 -552 17 -19 63 -82 103 -140 163 -237 265 -430 362 -690 46 -124 53 -145
91 -290 42 -156 76 -373 94 -605 18 -230 -15 -595 -77 -840 -8 -33 -19 -78
-24 -100 -22 -89 -95 -297 -141 -405 -110 -252 -196 -400 -379 -645 -119 -160
-367 -416 -509 -525 -25 -19 -47 -37 -50 -40 -10 -10 -116 -86 -159 -114 -352
-227 -693 -371 -1061 -446 -25 -5 -65 -14 -90 -19 -138 -30 -358 -43 -610 -37
-231 5 -294 11 -490 45 -99 16 -295 67 -355 90 -11 5 -38 14 -60 20 -113 34
-392 161 -505 230 -19 11 -42 24 -50 28 -13 6 -132 83 -214 139 -17 12 -37 29
-45 38 -7 9 -17 16 -22 16 -5 0 -19 9 -31 19 -13 11 -66 55 -118 99 -94 79
-250 235 -322 322 -21 25 -47 56 -59 70 -65 75 -208 284 -269 390 -29 51 -129
250 -158 316 -49 108 -130 350 -156 464 -9 41 -21 91 -26 110 -10 40 -34 193
-49 320 -14 110 -14 490 0 600 29 234 42 306 90 490 70 269 206 581 357 820
44 69 172 251 189 268 6 7 29 34 50 60 21 26 67 78 103 115 l65 69 58 -7 c32
-3 68 -10 80 -14 12 -5 40 -14 62 -21 57 -18 126 -67 152 -106 46 -71 84 -212
107 -394 32 -257 39 -434 27 -700 -16 -360 -16 -370 9 -370 11 0 20 4 20 9 0
19 126 137 205 193 44 31 78 50 185 102 14 7 54 21 89 31 75 23 81 34 81 138
0 76 23 440 40 642 6 66 10 165 10 221 0 86 3 107 23 145 37 69 55 72 442 77
325 3 461 -5 610 -38 225 -49 309 -76 455 -147 284 -138 475 -319 677 -644 96
-155 223 -431 223 -486 0 -10 5 -24 11 -30 24 -24 75 -276 94 -473 18 -186 18
-291 0 -470 -17 -177 -67 -434 -94 -488 -5 -10 -14 -35 -21 -57 -65 -218 -198
-470 -346 -657 -281 -355 -596 -531 -1039 -578 -212 -22 -205 -22 -205 -1 0
10 11 26 24 34 44 29 136 120 185 181 103 131 152 237 185 396 57 272 -54 564
-274 724 -55 40 -398 218 -511 265 -199 84 -533 249 -569 283 -49 45 -107 124
-134 181 -23 50 -27 72 -28 151 -1 118 25 182 110 264 75 74 149 111 275 137
90 19 174 19 269 1 184 -36 298 -109 399 -257 51 -75 209 -383 209 -407 0 -10
7 -27 15 -38 8 -10 15 -26 15 -33 0 -20 50 -71 69 -71 8 0 25 10 38 22 21 20
23 29 22 132 -1 238 -32 617 -55 672 -21 50 -51 46 -110 -16 -61 -64 -86 -65
-201 -10 -235 113 -362 145 -573 143 -115 0 -162 -5 -230 -22 -241 -64 -447
-221 -545 -419 -27 -53 -52 -106 -57 -117 -35 -93 -36 -342 0 -450 40 -122
130 -248 232 -325 44 -33 333 -184 475 -247 39 -18 102 -46 140 -63 302 -134
499 -289 582 -458 33 -66 38 -85 41 -168 8 -175 -52 -305 -188 -405 -89 -66
-251 -119 -363 -119 -58 0 -184 25 -243 48 -154 61 -286 180 -418 377 -104
155 -152 248 -221 430 -33 85 -51 107 -84 103 -18 -2 -31 -14 -43 -38 -15 -32
-16 -69 -7 -445 5 -229 14 -431 20 -458 19 -81 75 -86 126 -11 10 14 32 43 50
63 28 31 37 36 75 35 24 -1 63 -10 88 -22 225 -102 272 -120 420 -162 322 -89
938 -91 1420 -4 261 48 411 88 579 156 290 117 594 364 818 665 224 300 343
592 398 982 27 187 34 430 16 559 -5 37 -15 108 -21 157 -18 136 -61 303 -112
437 -54 142 -190 389 -259 473 -10 11 -44 54 -77 95 -192 241 -469 451 -757
575 -22 9 -48 21 -58 26 -32 17 -220 79 -312 103 -361 94 -646 124 -1105 116
-493 -9 -958 -5 -1155 8 -222 15 -357 30 -368 41 -12 12 116 119 268 223 299
205 636 358 975 444 103 25 130 31 310 60 208 35 574 38 800 8z"/>
<path d="M9095 9661 c-53 -24 -79 -55 -101 -117 -17 -49 -17 -55 -1 -113 10
-34 35 -101 57 -150 26 -58 40 -103 40 -130 0 -126 -170 -122 -258 5 -22 32
-27 50 -27 104 0 43 6 77 18 99 35 69 -1 53 -73 -34 -72 -85 -83 -108 -40 -85
26 14 32 8 50 -49 28 -87 151 -190 246 -206 55 -9 116 9 158 45 48 43 60 78
54 163 -5 59 -15 92 -57 184 -61 135 -64 170 -20 207 55 47 134 24 186 -53 25
-37 31 -109 13 -170 -19 -67 9 -54 83 38 l51 63 -30 -6 c-29 -6 -31 -5 -49 46
-24 69 -94 139 -160 161 -62 22 -90 21 -140 -2z"/>
<path d="M9656 9216 c8 -54 12 -50 -193 -203 -191 -144 -175 -130 -207 -177
-21 -31 -26 -50 -26 -100 0 -69 25 -125 91 -209 75 -95 146 -134 225 -123 61
8 110 35 255 141 189 138 234 154 278 101 7 -9 17 -16 22 -16 18 0 8 24 -29
74 -21 28 -57 77 -80 109 -41 55 -72 77 -57 40 4 -10 9 -35 12 -56 5 -33 2
-43 -28 -74 -53 -56 -308 -232 -353 -244 -83 -22 -163 15 -211 98 -37 62 -39
123 -4 176 23 35 149 136 302 240 77 53 122 61 152 27 17 -19 45 -27 45 -14 0
3 -19 31 -43 62 -24 31 -64 87 -90 125 -27 37 -52 67 -57 67 -6 0 -7 -19 -4
-44z"/>
<path d="M2790 8459 c-107 -21 -221 -112 -276 -221 -27 -53 -154 -337 -154
-344 0 -13 26 0 47 22 30 32 38 31 203 -43 69 -31 174 -78 234 -105 60 -26
115 -56 123 -66 8 -11 12 -33 10 -59 -6 -63 15 -54 48 20 15 34 45 100 67 147
103 222 119 290 93 391 -36 142 -116 221 -258 254 -69 16 -73 16 -137 4z m122
-149 c83 -31 149 -80 183 -135 26 -41 30 -58 29 -115 0 -77 -28 -145 -77 -186
-37 -31 -63 -29 -157 13 -36 17 -138 63 -228 103 -89 40 -164 76 -166 79 -6
10 43 122 73 165 51 76 121 109 221 102 36 -2 91 -13 122 -26z"/>
<path d="M10193 8421 c11 -53 -3 -61 -323 -201 -36 -15 -91 -40 -123 -54 -77
-35 -110 -34 -143 4 -43 48 -46 25 -8 -63 20 -45 47 -109 61 -142 101 -242
203 -344 315 -315 44 12 93 51 113 91 17 32 20 108 6 157 -5 17 -8 32 -6 32 3
0 28 -18 57 -40 44 -33 61 -40 100 -40 41 0 52 5 87 38 52 50 61 101 33 203
-28 104 -142 359 -160 359 -11 0 -13 -8 -9 -29z m91 -246 c14 -36 26 -75 26
-88 0 -29 -36 -87 -54 -87 -8 0 -16 -5 -18 -12 -3 -7 -26 -12 -60 -12 -47 -1
-61 3 -83 24 -28 26 -75 109 -75 133 0 14 189 104 221 106 12 1 25 -20 43 -64z
m-285 -121 c15 -38 24 -81 25 -121 1 -59 -1 -65 -34 -97 -22 -22 -53 -40 -83
-48 -43 -11 -52 -11 -85 7 -77 41 -119 177 -69 222 25 24 197 104 212 100 5
-2 20 -30 34 -63z"/>
</g>
</svg>




		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
